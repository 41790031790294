import * as Sentry from "@sentry/vue";

type CartItem = {
  quantity: number | string;
  id: number | string;
  properties?: object;
};

type AddToCartPayload = {
  items: CartItem[];
  attributes?: object;
};

type ChangeCartPayload = {
  line?: number | string;
  id?: number | string;
  quantity: number;
  properties?: object;
};

type UpdateCartPayload = {
  [key: number]: number;
};

const { routes } = useTheme();

const client = axios.create({
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

async function clearCart() {
  try {
    const { data } = await client.post(`${routes.cart_clear_url}.js`, {});
    const attributes: any = {};

    for (const [key] of Object.entries(data.attributes)) {
      attributes[key] = "";
    }

    const cart = await updateCart({ attributes });

    return cart;
  } catch (e: any) {
    Sentry.captureException(e);
  }
}

async function changeQuantity(variant_id: number, quantity: number) {
  try {
    const updates: UpdateCartPayload = {
      [variant_id]: quantity,
    };
    const { data } = await client.post(routes.cart_update_url, { updates });

    return data;
  } catch (error) {
    console.error(error);
  }
}

async function removeItem(variant_id: number) {
  try {
    const updates: UpdateCartPayload = {
      [variant_id]: 0,
    };
    const { data } = await client.post(routes.cart_update_url, { updates });

    return data;
  } catch (error) {
    console.error(error);
  }
}

async function updateCart(payload: any) {
  try {
    const { data } = await client.post(routes.cart_update_url, payload);

    return data;
  } catch (e: any) {
    Sentry.captureException(e);
  }
}

async function changeCart(payload: ChangeCartPayload) {
  try {
    const { data } = await client.post(routes.cart_change_url, payload);

    return data;
  } catch (e: any) {
    Sentry.captureException(e);
  }
}

async function addToCart(payload: AddToCartPayload) {
  try {
    const { data } = await client.post(routes.cart_add_url, payload);

    return data;
  } catch (e: any) {
    Sentry.captureException(e);
  }
}

async function fetchCart() {
  try {
    const { data } = await client.get("/cart.js");

    return data;
  } catch (e: any) {
    Sentry.captureException(e);
  }
}

function goToCheckout(params = "") {
  window.location.href = `/checkout${params}`;
}

export default () => ({
  clearCart,
  addToCart,
  goToCheckout,
  fetchCart,
  removeItem,
  changeQuantity,
  changeCart,
  updateCart,
});
